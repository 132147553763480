body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .date-label {
  padding-top: 100px !important;
  margin-bottom: 100px !important;
  padding-bottom: 100px !important;
} */

.mdb-react-date__picker{
  margin: 0 !important;
  padding: 0 !important;
  margin-left: 20px !important;
  width: 50% !important;
}

div.mdb-form >div.mdb-react-date__picker{
  margin: 0 !important;
  padding: 0 !important;
}

.card{
  margin-bottom: 10px
}

.black-text{
  color : black;
}

.nav-link{
  color: black;
  font-size: 16px;
}

@media (max-width: 700px){
  .navbar-brand{
    width: 40% !important;
    height: 100%;

  }
}

@media (min-width: 701px){
  .navbar-brand{
    width: 10% !important;
    height: 100%;

  }
}
.brand-logo{
  width: 100%;
}

.dropdown-menu {
  right: 0 ;
  left: auto;
}

